import CryptoJS from "crypto-js";
let path11 = window.location.host.split(":")[0] == "localhost" ? "http://localhost:44330" : window.location.protocol + "//" + window.location.host;
export default {
  // 接口地址
  API_BASE_URL: window.location.host.split(":")[0] == "localhost" ? "http://localhost:44330" : window.location.protocol + "//" + window.location.host,
  path: window.location.host.split(":")[0] == "localhost" ? "http://localhost:44330" : window.location.protocol + "//" + window.location.host,
  // API_BASE_URL: window.location.host.split(":")[0] == "localhost" ? window.location.protocol + "//ks.hongxzx.net.cn/" : window.location.protocol + "//" + window.location.host,
  // path: window.location.host.split(":")[0] == "localhost" ? window.location.protocol + "//ks.hongxzx.net.cn/" : window.location.protocol + "//" + window.location.host,
  uploadPath: window.location.protocol + "//wenjian.hongxzx.net.cn/", //window.location.protocol + '://wenjian.hongxzx.net.cn/',
  // serviceTimeApi: window.location.protocol + "//ysks.hongxzx.com.cn/api/Examination/GetServiceTime", // 获取服务器时间的接口
  serviceTimeApi: path11.substr(path11.length - 1, 1) == "/" ? path11.substr(0, path11.length - 1) : path11 + "/api/ExamList/GetServiceTime", // 获取服务器时间的接口
	
  // 解密
  Decrypt(str) {
    var KEY = "borainsoftmasterborainsoftmaster";
    var IV = "borainsoftmaster"; // 16位
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },

  // 加密
  Encrypt(str) {
    var KEY = "borainsoftmasterborainsoftmaster";
    var IV = "borainsoftmaster"; // 16位
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encrypted = "";
    var srcs = CryptoJS.enc.Utf8.parse(str);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
  },

  // 时间格式处理(返回年月日)
  timeFormat(timeStr, typeStr) {
    let years = "", months = "", days = "", hours = "", minutes = "", seconds = "", returnStr = "--";
    if (timeStr) {
      timeStr = timeStr.indexOf("T") != -1 ? timeStr.replace("T", " ") : timeStr;
      timeStr = timeStr.replace(/\-/g, "/");
      if ( timeStr.indexOf("0001") == -1 && timeStr.indexOf("9999") == -1 && timeStr.indexOf("1900") == -1 && timeStr.indexOf("1970") == -1 ) {
        years = new Date(timeStr).getFullYear();
        months = new Date(timeStr).getMonth() + 1 >= 10 ? new Date(timeStr).getMonth() + 1 : "0" + (new Date(timeStr).getMonth() + 1);
        days = new Date(timeStr).getDate() >= 10 ? new Date(timeStr).getDate() : "0" + new Date(timeStr).getDate();
        hours = new Date(timeStr).getHours() >= 10 ? new Date(timeStr).getHours() : "0" + new Date(timeStr).getHours();
        minutes = new Date(timeStr).getMinutes() >= 10 ? new Date(timeStr).getMinutes() : "0" + new Date(timeStr).getMinutes();
        seconds = new Date(timeStr).getSeconds() >= 10 ? new Date(timeStr).getSeconds() : "0" + new Date(timeStr).getSeconds();
        if (typeStr) {
          if (typeStr == "YYYY-MM-DD") {
            returnStr = years + "-" + months + "-" + days;
          }
          if (typeStr == "YYYY-MM-DD hh:mm:ss") {
            returnStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes + ":" + seconds;
          }
          if (typeStr == "YYYY/MM/DD hh:mm:ss") {
            returnStr = years + "/" + months + "/" + days + " " + hours + ":" + minutes + ":" + seconds;
          }
          if (typeStr == "YYYY/MM/DD") {
            returnStr = years + "/" + months + "/" + days;
          }
          if (typeStr == "YYYY/MM") {
            returnStr = years + "/" + months;
          }
          if (typeStr == "MM/DD") {
            returnStr = months + "/" + days;
          }
          if (typeStr == "YYYY年MM月DD日 hh:mm:ss") {
            returnStr = years + "年" + months + "月" + days + "日 " + hours + ":" + minutes + ":" + seconds;
          }
          if (typeStr == "YYYY-MM") {
            returnStr = years + "-" + months;
          }
          if (typeStr == "MM-DD") {
            returnStr = months + "-" + days;
          }
          if (typeStr == "hh:mm:ss") {
            returnStr = hours + ":" + minutes + ":" + seconds;
          }
        } else {
          returnStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes + ":" + seconds;
        }
      }
    }
    return returnStr;
  },

  // 时间格式处理(将 new Date  返回其他格式)
  /**
   * 时间格式处理(将 new Date  返回其他格式)
   * @param {dateTime}  new Date格式数据
   * @param {type} 需要返回的时间格式
   * */
  formatDateType(dateTime, type) {
    var returnTime = "--";
    let yearsVal, monthVal, dayVal, hourVal, minuteVal, secondVal;
    if (dateTime) {
      dateTime = dateTime.indexOf("T") != -1 ? dateTime.replace("T", " ") : dateTime;
      dateTime = dateTime.replace(/\-/g, "/");
      yearsVal = new Date(dateTime).getFullYear();
      monthVal = new Date(dateTime).getMonth() + 1 >= 10 ? new Date(dateTime).getMonth() + 1 : "0" + (new Date(dateTime).getMonth() + 1);
      dayVal = new Date(dateTime).getDate() >= 10 ? new Date(dateTime).getDate() : "0" + new Date(dateTime).getDate();
      hourVal = new Date(dateTime).getHours() >= 10 ? new Date(dateTime).getHours() : "0" + new Date(dateTime).getHours();
      minuteVal = new Date(dateTime).getMinutes() >= 10 ? new Date(dateTime).getMinutes() : "0" + new Date(dateTime).getMinutes();
      secondVal = new Date(dateTime).getSeconds() >= 10 ? new Date(dateTime).getSeconds() : "0" + new Date(dateTime).getSeconds();
      switch (type) {
        case "年-月-日 hh:MM:ss":
          returnTime = yearsVal + "年" + monthVal + "月" + dayVal + "日 " + hourVal + ":" + minuteVal + ":" + secondVal;
          break;
        case "年-月-日 时:分:秒":
          returnTime = yearsVal + "年" + monthVal + "月" + dayVal + "日 " + hourVal + "时" + minuteVal + "分" + secondVal + "秒";
          break;
        case "年-月-日":
          returnTime = yearsVal + "年" + monthVal + "月" + dayVal + "日";
          break;
        case "年-月":
          returnTime = yearsVal + "年" + monthVal + "月";
          break;
        case "月-日":
          returnTime = monthVal + "月" + dayVal + "日";
          break;
        case "yyyy/mm/dd hh:MM:ss":
          returnTime = yearsVal + "/" + monthVal + "/" + dayVal + " " + hourVal + ":" + minuteVal + ":" + secondVal;
          break;
        case "yyyy/mm/dd":
          returnTime = yearsVal + "/" + monthVal + "/" + dayVal;
          break;
        case "yyyy/mm":
          returnTime = yearsVal + "/" + monthVal;
          break;
        case "mm/dd":
          returnTime = monthVal + "/" + dayVal;
          break;
        case "yyyy-mm-dd":
          returnTime = yearsVal + "-" + monthVal + "-" + dayVal;
          break;
        case "yyyy-mm":
          returnTime = yearsVal + "-" + monthVal;
          break;
        case "mm-dd":
          returnTime = monthVal + "-" + dayVal;
          break;
        case "hh:MM:ss":
          returnTime = hourVal + ":" + minuteVal + ":" + secondVal;
          break;
        case "hh:MM":
          returnTime = hourVal + ":" + minuteVal;
          break;
        case "MM:ss":
          returnTime = minuteVal + ":" + secondVal;
          break;
        case "时:分:秒":
          returnTime = hourVal + "时" + minuteVal + "分" + secondVal + "秒";
          break;
        case "yyyy-mm-dd hh:MM:ss":
          returnTime = yearsVal + "-" + monthVal + "-" + dayVal + " " + hourVal + ":" + minuteVal + ":" + secondVal;
          break;
        default:
          returnTime = yearsVal + "-" + monthVal + "-" + dayVal + " " + hourVal + ":" + minuteVal + ":" + secondVal;
      }
    }
    return returnTime;
  },

  // 判断是否开启禁用远程软件/通讯软件
  isRemote(fnA, fnB) {
    window.sessionStorage.removeItem("remoteCheck");
    // 一:判断是否安装插件
    // 未安装--提示进行下载并安装；已安装--运行插件
    // 二:判断是否存在已打开的远程操作应用 0：不存在    1：存在
    // 0: 进入后续考试流程
    // 1: 给出提示,并停留在当前页面
    if ("WebSocket" in window) {
      let ws = new WebSocket("ws://127.0.0.1:12345");
      ws.onmessage = function (evt) {
        if (evt.data.split("_")[0] == 0) {
          if (fnA && typeof fnA == "function") {
            // 进行后续操作
            fnA();
          }
        } else {
          let checkStr = evt.data.split("_")[1];
          if (fnB && typeof fnB == "function") {
            window.sessionStorage.setItem("remoteCheck", checkStr);
            // 当前用户存在已开启的远程软件/通讯软件
            fnB("remoteCheck");
          }
        }
      };
      ws.onclose = function () {
        if (fnB && typeof fnB == "function") {
          // 未下载安装系统检测插件
          fnB("downLoadCheat");
        }
      };
    } else {
      if (fnB && typeof fnB == "function") {
        // 检测成功
        fnB("isCheckTrue");
      }
    }
  },

  // 文件下载
  downloadFile(url, filename) {
    const prefix = window.location.host.split(":")[0] == "localhost" ? "http://localhost:49588" : window.location.protocol + "//" + window.location.host;
    let ele = document.createElement("a");
    ele.setAttribute("href", prefix + url); //设置下载文件的url地址
    ele.setAttribute("download", filename); //用于设置下载文件的文件名
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
  },

  /**
   * 将秒数转换为时分秒格式数据
   * @param {numVal} 单位：秒
   * */
  seToHMS(numVal) {
    let returnStr = "", hourStr = "", minuteStr = "", secondStr = "";
    if (numVal >= 0) {
      var hourVal = Math.floor(numVal / (60 * 60));
      var minuteVal = Math.floor((numVal - hourVal * 60 * 60) / 60);
      var secondVal = numVal - hourVal * 60 * 60 - minuteVal * 60;
      if (hourVal >= 10) {
        hourStr = hourVal;
      } else if (hourVal > 0 && hourVal < 10) {
        hourStr = "0" + hourVal;
      } else if (hourVal <= 0) {
        hourStr = "00";
      }
      if (minuteVal >= 10) {
        minuteStr = minuteVal;
      } else if (minuteVal > 0 && minuteVal < 10) {
        minuteStr = "0" + minuteVal;
      } else if (minuteVal <= 0) {
        minuteStr = "00";
      }
      if (secondVal >= 10) {
        secondStr = secondVal;
      } else if (secondVal > 0 && secondVal < 10) {
        secondStr = "0" + secondVal;
      } else if (secondVal <= 0) {
        secondStr = "00";
      }
      return (returnStr = hourStr + ":" + minuteStr + ":" + secondStr);
    } else {
      return (returnStr = "00:00:00");
    }
  },

  // 时间格式转秒数(时间格式：01:12:33)
  timeToSe(numVal) {
    let hourVal = numVal.split(":")[0];
    let minuteVal = numVal.split(":")[1];
    let secondVal = numVal.split(":")[2];
    // 秒数
    let secondNum = Number(hourVal) * 60 * 60 + Number(minuteVal) * 60 + Number(secondVal);
    return secondNum;
  },
};
