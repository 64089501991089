import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		$token: null,  //token
  },
  getters: {
  },
  mutations: {
		setToken(state, dataStr){
			state.$token = dataStr;
		},
  },
  actions: {
  },
  modules: {
  }
})
