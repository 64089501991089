import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "登录",
    component: () => import("@/views/login"),
  },
  {
    path: "/selectExam",
    name: "选择考试",
    component: () => import("@/views/selectExam"),
  },
  {
    path: "/examConfirm",
    name: "考试信息确认",
    component: () => import("@/views/examConfirm"),
  },
  {
    path: "/examCountDown",
    name: "考试倒计时",
    component: () => import("@/views/examCountDown"),
  },
	{
	  path: "/equipmentInspection",
	  name: "考生设备检测",
	  component: () => import("@/views/equipmentInspection"),
	},
	{
	  path: "/userSubCamera",
	  name: "开启副摄像头",
	  component: () => import("@/views/userSubCamera"),
	},
	{
	 path: "/examCont",
	 name: "考生在线答题",
	 component: () => import("@/views/examCont"),
	}
];

const router = new VueRouter({
  routes,
});

// 不需要登录的路由
export const WHITE_LIST = ["/", "/userSubCamera"];
// 项目名称
export const PROJECT_NAME = "理论知识在线考试系统";
// 刷新路由的路由地址
export const REDIRECT_PATH = "/redirect";

// 在VueRouter上配置路由跳转，在router中的index.js中加上以下代码，注意加在use之前
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => {});
};

router.beforeEach((to, from, next) => {
  updateTitle(to);
  if (sessionStorage.getItem("examStuToken")) {
    next();
  } else if (WHITE_LIST.includes(to.path)) {
    next();
  } else {
    next({
      path: "/",
    });
  }
});

/**
 * 更新浏览器标题
 * @param route 路由信息
 */
function updateTitle(route) {
  if (route && !route.path?.includes(REDIRECT_PATH)) {
    const names = [];
    if (route.name) {
      names.push(route.name);
    }
    if (PROJECT_NAME) {
      names.push(PROJECT_NAME);
    }
    document.title = names.join(" - ");
  }
}

export default router;
